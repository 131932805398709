import React from 'react';
import logo from './logo.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" /> 
        <h1> GENERAL EXTRACT BREWING INSTRUCTIONS </h1>
      </header>
      <Container>
        <div className="row">
          <div className="col-sm-4">
            <div><u>Before you Begin</u></div>
            <div>Necessary Equipment:</div>
            <div className="ml-3">- Boil kettle, at least 3 – 3.5 gallons</div>
            <div className="ml-3">- Fermenter: bucket or carboy, airlock</div>
            <div className="ml-3">- 48 12-oz, or 24 22-oz bottles</div>
            <div>Unpack the Kit:</div>
            <div className="ml-3">- Refrigerate yeast when you get home</div>
            <div className="ml-3">- Double-check actual box contents against those listed above</div>
            <div className="Title my-2"><b>Procedure:</b></div>
            <div><u>Brew Day</u></div>
            <div className="mb-3">1. Remove the yeast from the fridge and allow to warm. This will take a few hours. White Labs and Omega do not require activation like other smack packs.</div>
            <div className="mb-3">2. Heat 2.5 - 3 gallons of water to 155 Fahrenheit.</div>
            <div className="mb-3">3. Pour milled grains into mesh mag bag and tie open end into a knot. Steep the specialty grains at 155 Fahrenheit for 20 minutes.</div>
            <div className="mb-3">4. Remove the grain bag and allow to drain into the pot. Discard once finished.</div>
            <div className="mb-3">5. Bring the kettle to a boil. Remove from the heat and add the extracts (see box for type & amounts) Stir continuously to mix and prevent burning on the bottom of the kettle.</div>
            <div>6. Once all the malt is mixed, return to heat and bring to a boil.</div>
            <div className="mb-3 ml-3">- Add hops as printed on box</div>
            <div>7. Cool the wort. Remove the kettle from heat and cool by using a wort chiller or placing the kettle in an ice bath in your sink.</div>
          </div>
          <div class="clearfix visible-sm-block"></div>
          <div className="col-sm-4">
            <div className="mb-3">8. Sanitize fermenting equipment and yeast pack. <b><i>Anything that touches the wort after the boil MUST be sanitized.</i></b></div>
            <div className="mb-3">9. Add cool water to fermenter bucket to top up to 5 gallons.</div>
            <div className="mb-3">10. Take gravity reading: <b><i>Measured OG:________</i></b></div>
            <div className="mb-3">11. Transfer cooled wort to sanitized fermenter bucket, leaving any thick sludge in the kettle.</div>
            <div className="mb-3">12. Aerate the wort by stirring (splashing) for a few minutes.</div>
            <div>13. Remove the lid and pitch yeast:</div>
            <div className="ml-3">- White Labs: cut through outer and inner package and squeeze contents into fermenter</div>
            <div className="ml-3">- Omega: shake for 30 seconds, cut off a corner and pour into fermenter.</div>
            <div className="mb-3 ml-3">- Dry Yeast: open package and sprinkle on top of the wort. Do not mix in.</div>
            <div className="mb-3">14. Seal the fermenter with lid and place a sanitized airlock into the hole in the lid. Fill airlock to the line with sanitizer. Place fermenter in a dark, quiet place.</div>
            <div>15. Fermentation will begin within about 48 hours, indicated by a bubbling airlock. Fermentation will continue for 2-3 weeks.</div>
          </div>
          <div class="clearfix visible-sm-block"></div>
          <div className="col-sm-4">
            <div><u>Bottling Day</u></div>
            <div className="mb-3">16. Sanitize siphoning and bottling equipment.</div>
            <div className="mb-3">17. Take final gravity reading: <b><i>Measured FG:________</i></b></div>
            <div className="mb-3">18. Transfer the beer from the fermenter to the sanitized bottling bucket. Use caution not to aerate the beer by splashing.</div>
            <div className="mb-3">19. Prepare priming solution by mixing priming sugar with 3⁄4 cup of boiling water. Add to bottling bucket and mix well into the beer.</div>
            <div className="mb-3">20. Fill bottles using bottling wand and cap securely.</div>
            <div><u>Conditioning</u></div>
            <div className="mb-3">21. Condition bottles at <b><i>room temperature</i></b> for 1-2 weeks.</div>
            <div className="mb-3">22. Once carbonated, chill bottles and enjoy!</div>
            <div className="Beer text-center"><i className="fas fa-beer"/>&ensp;<b>Cheers!</b></div>
          </div>
        </div>
      </Container>
      <div className="Video">
        <h3>Video Tutorials</h3>
        <iframe width="731" height="411" src="https://www.youtube.com/embed/pg2v7JW2Wk8?list=PLFnzXJ-QwarsL6VdmOvbHaFmWr1GYwJ7P" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <Container>
        <div className="Contact row">
          <div className="col-sm-8">
            <div><h3>Specific questions on your brew?</h3></div>
            <div><h5>Please contact us at: <a href = "mailto: info@schoolhousebeer.com">info@schoolhousebeer.com</a></h5></div>
            <div className="mt-3"><a href="https://www.schoolhousebeer.com/" class="btn">Learn More</a></div>
          </div>
          <div class="clearfix visible-sm-block"></div>
          <div className="col-sm-4">
            <div><h3>Come Visit Us</h3></div>
            <div>840 Franklin Ct. Suite 100</div>
            <div>Marietta, GA 30067</div>
          </div>
         </div>
      </Container>
    </div>
  );
}

export default App;